<template>
    <div>
      <van-cell-group title="通用">
        <van-cell title="记录查询" is-link size="large" icon="records" to="/org/teahonor/record" />
        <van-cell title="新增荣誉" is-link size="large" icon="plus" to="/org/teahonor/new" />
      </van-cell-group>
    </div>
    </template>
    
    <script>
    export default {
      name: "index"
    }
    </script>
    
    <style scoped></style>